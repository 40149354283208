import React, { useEffect, useState } from "react"
import API from "../../common/API"
import { parsedUser } from "../../common/GetCurrentUser"
import { Card, Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import TotalVisitMonthly from "./TotalVisitMonthly"

function DashboardContainer() {
  const user = parsedUser()
  const { t } = useTranslation()
  const [generalInfo, setGeneralInfo] = useState(null)
  const [summaryByStage, setSummaryByStage] = useState([])
  const [summaryByStatus, setSummaryByStatus] = useState([])
  const bindDataRemote = async () => {
    try {
      let record = await API.getAction(
        "dashboard/general?organizationId=" + user.OrganizationId
      )
      if (record.data.status === "ok") {
        setGeneralInfo(record.data.response)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
    }
  }
  const bindDataByStageRemote = async () => {
    try {
      let record = await API.getAction(
        "place/summaryByStage?organizationId=" + user.OrganizationId
      )
      if (record.data.status === "ok") {
        setSummaryByStage(record.data.response)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
    }
  }

  const bindDataByStatusRemote = async () => {
    try {
      let record = await API.getAction(
        "place/summaryByStatus?organizationId=" + user.OrganizationId
      )
      if (record.data.status === "ok") {
        setSummaryByStatus(record.data.response)
      }
    } catch (ex) {
      console.error(ex)
    } finally {
    }
  }
  useEffect(() => {
    bindDataRemote()
    bindDataByStageRemote()
    bindDataByStatusRemote()
  }, [])
  return (
    <Container>
      <Row className="my-4">
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>{generalInfo && generalInfo.totalPlaces}</Card.Title>
            </Card.Header>
            <Card.Body className="p-1 px-4">
              <i className="fa fa-map-location me-2"></i>
              {t("Places")}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>
                {generalInfo && generalInfo.totalVehicles}
              </Card.Title>
            </Card.Header>
            <Card.Body className="p-1 px-4">
              <i className="fa fa-car me-2"></i>
              {t("Vehicles")}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>{generalInfo && generalInfo.totalUsers}</Card.Title>
            </Card.Header>
            <Card.Body className="p-1 px-4">
              <i className="fa fa-user me-2"></i>
              {t("Agents")}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <Card.Title>{generalInfo && generalInfo.totalVisits}</Card.Title>
            </Card.Header>
            <Card.Body className="p-1 px-4">
              <i className="fa fa-calendar me-2"></i>
              {t("Visits")}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        {/* SUMMARY BY STAGE */}
        <Col xs={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>{t("Summary by Stage")}</Card.Title>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <table className="table table-striped">
                  <tbody>
                    {summaryByStage.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <i className={item.icon + " me-2"}></i>
                          {item.name}
                        </td>
                        <td className="text-end">{item.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <TotalVisitMonthly />
        </Col>

        {/* SUMMARY BY Status */}
        <Col xs={12} md={6}>
          <Card>
            <Card.Header>
              <Card.Title>{t("Summary by Status")}</Card.Title>
            </Card.Header>
            <Card.Body className="p-0">
              <div className="table-responsive">
                <table className="table table-striped">
                  <tbody>
                    {summaryByStatus.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <i
                            className={item.icon + " me-2"}
                            style={{ color: item.color }}
                          ></i>
                          {item.name}
                        </td>
                        <td className="text-end">{item.count}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default DashboardContainer
